export const BASE_URL = 'https://api.ablejobshub.com/api';
export const LOGIN= `${BASE_URL}/user_login/`;
export const REGISTER = `${BASE_URL}/employee_signup/`;
export const EMPLOYER_REGISTER = `${BASE_URL}/employer_signup/`
export const TRAINER_REGISTER = `${BASE_URL}/trainer_signup/`
export const VOLUNTEER_REGISTER = `${BASE_URL}/volunteer_signup/`
export const CREATE_JOB = `${BASE_URL}/add_jobs/`;
export const GET_JOB = `${BASE_URL}/jobs/`;
export const GET_JOB_EMPLOYEE = `${BASE_URL}/jobs_list/`;
export const APPLIED_CANDIDATES = `${BASE_URL}/job_applications/`;
export const SHORTLIST_APPLICATIONS = `${BASE_URL}/jobs_shorts`;
export const SHORTLIST_ONBOARD = `${BASE_URL}/shortlisted_job_applications/`;
export const ONBOARD_CANDIDATE = `${BASE_URL}/onboard_application/`;
export const ONBOARDED_LIST =  `${BASE_URL}/onboarded_job_applications/`;
export const SHORTLIST_CANDIDATES = `${BASE_URL}/shortlist_application/`;
export const VOLUNTEERS_LIST = `${BASE_URL}/volunteers_list/`;
export const EMPLOYEES_LIST = `${BASE_URL}/employees_list/`;
export const EMPLOYERS_LIST = `${BASE_URL}/employers_list/`;
export const TRAINERS_LIST = `${BASE_URL}/trainers_list/`;
export const JOBS_LIST_BASED_ON_EMPLOYER =  `${BASE_URL}/A_employerJobsView/`;
export const DELETE_JOB = `${BASE_URL}/jobs/delete/`;
export const UPDATE_JOB = `${BASE_URL}/jobs/edit/`
export const ACTIVATE_JOB = `${BASE_URL}/jobs/deactivate/`
export const FORGOT_PASSWORD =`${BASE_URL}/forgot_password/`;
export const RESET_PASSWORD = `${BASE_URL}/reset_password/`
export const APPLY_JOB = `${BASE_URL}/apply_job/`;
export const APPLIED_JOB = `${BASE_URL}/applied_jobs/`;
export const SHORTLIST_JOB = `${BASE_URL}/shortlisted_jobs/`;
export const ONBOARD_JOB = `${BASE_URL}/onboarded_jobs/`;
export const UPLOAD_RESUME = `${BASE_URL}/upload_resume/`;
export const USER_PROFILE = `${BASE_URL}/user_profile/`;
export const PROFILE_UPDATE = `${BASE_URL}/userprofile_Update/`;
export const PROFILE_UPDATE_IMAGE = `${BASE_URL}/upload-profile-image/`;
export const ADIMN_EMPLOYEE_LIST = `${BASE_URL}/employees_list/`;

export const CHAIR_URL = `${BASE_URL}/chairs`;
export const DECORATION_URL = `${BASE_URL}/decoration`;
export const CALCULATE_PRICE_URL = `${BASE_URL}/calculate_price`;
export const NON_VEG_ITEMS = `${BASE_URL}/non_veg_items`;
export const VEG_ITEMS = `${BASE_URL}/veg_items`;
export const FOOD_ITEMS = `${BASE_URL}/food_items`;
export const MAKEUP_ITEMS = `${BASE_URL}/makeup_items`;
export const ENTERTAINMENT = `${BASE_URL}/entertainment_items`;
export const VENUE  = `${BASE_URL}/venues`;
export const TRANSPORT  = `${BASE_URL}/transport`;
export const PHOTOGRAPHY  = `${BASE_URL}/photography`;
export const CORPORATE_DEC= `${BASE_URL}/cedecoration`;
export const CORPORATE_CHAIRS= `${BASE_URL}/cecandt`;
export const CORPORATE_GIFTS= `${BASE_URL}/cegifts`;
export const CORPORATE_SHEILDS= `${BASE_URL}/cesheilds`;

export const BDECORATION= `${BASE_URL}/bdecoration`;
export const BCAKE= `${BASE_URL}/bcake`;
export const BGIFTS= `${BASE_URL}/bgifts`;

export const VSIGNUP =`${BASE_URL}/signup`;
export const VSIGNIN =`${BASE_URL}/signin`;
export const V_OTP =`${BASE_URL}/verify_otp?email=`;

export const VENDER_LIST =`${BASE_URL}/vendorslist`;
export const ADD_EVENT =`${BASE_URL}/add_event`;
export const EVENTS_LIST =`${BASE_URL}/events_list`;
export const VENDOR_QUOTE =`${BASE_URL}/vendor_tendor`;
export const EVENT_CHAT_HISTORY =`${BASE_URL}/event_chat_history`;
export const EVENT_MESSAGE =`${BASE_URL}/eventmsg`;
export const DEVENT_LIST =`${BASE_URL}/devents_list`;
export const VEVENT_HISTORY =`${BASE_URL}/vevent_history`;
export const DEACTVATE_EVENT =`${BASE_URL}/deactivate_event`;
export const VEVENT_LIST =`${BASE_URL}/vevent_list`;
export const ADD_EVENT_ITEMS =`${BASE_URL}/add_event_items?event_id=`;
export const EVENT_ITEMS =`${BASE_URL}/event_items?event_id=`;
export const ASSIGN_CONTRACT =`${BASE_URL}/assign_contract`;
export const CONTRACT_DATA =`${BASE_URL}/contract-data`;


